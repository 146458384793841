import React, { useContext, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import EmptyState from 'components/EmptyState'
import ResultDetails from 'components/Extrahub/ResultDetails'
import { Modal } from 'components/Modal'

import { cn } from 'utils/styling'

const ReceiptModal = ({ open, receipt, close, detail, hideValidProducts, children }) => {
  const { translate } = useContext(LangContext)

  const [fullSize, setFullSize] = useState(false)

  const fullName = (receipt) => {
    if (receipt?.uploadedByContact) return `${receipt.contact.firstname} ${receipt.contact.lastname}`
    if (receipt?.uploadedBy) return fullNameEmployee(receipt.employee)

    return translate('components.Extrahub.ListReceipts.unknownContact')
  }

  const fullNameEmployee = (employee) => {
    return `${employee?.firstName} ${employee?.lastName}`
  }

  return (
    <Modal title={translate('components.Extrahub.ListReceipts.titleModal')} open={open} onHandleClose={close}>
      {receipt ? (
        <div>
          <div className="relative mb-4">
            <div
              className={cn(
                'h-64 w-full rounded-md bg-slate-100 p-1 shadow-inner ',
                fullSize ? 'overflow-y-auto' : 'flex items-center justify-center overflow-y-auto'
              )}
            >
              <img
                src={receipt.preview}
                className={cn(
                  'rounded-sm object-contain ring-1 ring-slate-900/10',
                  fullSize ? 'h-auto w-full' : 'h-auto max-h-60 max-w-full'
                )}
              />
            </div>
            <div className="absolute bottom-4 right-4 ">
              <Button secondary onClick={() => setFullSize(!fullSize)}>
                {fullSize ? 'Fit' : 'Zoom in'}
              </Button>
            </div>
          </div>
          <div className="rounded-md border-2 p-4">
            <p>
              {translate('components.Extrahub.ListReceipts.contact')}: {`${fullName(receipt)}`}
            </p>
            <p>
              {translate('components.Extrahub.ListReceipts.submitted')}:{' '}
              {moment(receipt.createdAt).format('YYYY-MM-DD')}
            </p>
            <p>
              {translate('components.Extrahub.ListReceipts.status')}: {receipt.status}
            </p>
            {receipt.approverBy && (
              <p>
                {translate('components.Extrahub.ListReceipts.approverBy')}: {`${fullNameEmployee(receipt.approver)}`}
              </p>
            )}
            <p>{translate('components.Extrahub.ListReceipts.message')}:</p>
            <p>{translate(`components.Extrahub.Result.ErrorMessage.${receipt.status}`)}</p>
          </div>
          {detail && (
            <div className="mt-4 rounded-md border-2 p-4">
              <ResultDetails data={receipt} hideValidProducts={hideValidProducts} />
            </div>
          )}
          {children}
        </div>
      ) : (
        <EmptyState
          title={translate('components.Extrahub.ListReceipts.EmptyState.title')}
          subtitle={translate('components.Extrahub.ListReceipts.EmptyState.subtitle')}
        />
      )}
    </Modal>
  )
}

ReceiptModal.propTypes = {
  open: PropTypes.bool,
  receipt: PropTypes.object,
  close: PropTypes.func,
  detail: PropTypes.bool,
  hideValidProducts: PropTypes.bool,
  children: PropTypes.node
}

export default ReceiptModal
