import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { bool, node, object, string } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import SmallCaps from 'components/SmallCaps'

import { getExtrahubContactinfo } from 'utils/helpers'

import ExtraFundsDefaultLogo from 'static/images/advance/actions/extraFundsDefaultLogo.png'

import ProgramStartEnd from './ProgramStartEnd'

const RetailerExtraHubCard = ({ children, program, detail, contactId }) => {
  const { lang, translate } = useContext(LangContext)

  const { userRewardedAmount, userSoldUnits } = getExtrahubContactinfo({ program, contactId })

  const navigate = useNavigate()

  return (
    <div className="relative my-6  flex w-full flex-col gap-5 rounded-lg border border-slate-200 bg-white p-4 shadow-sm hover:border-slate-300 hover:shadow-md">
      <div className="flex gap-5">
        <img src={program.imageUrl || ExtraFundsDefaultLogo} className="size-[150px] shrink-0 rounded" />

        <div className="flex-1">
          <h3 className="mb-2">{lang === 'en' ? program.englishName : program.frenchName}</h3>
          <SmallCaps>
            {program.variantFocus || program.brandFocus} • {program.paymentType || 'offline'} payment
          </SmallCaps>

          <div className="mb-2 text-xs text-gray-400">
            <b>{program.activity}</b>
            <p>{program.description}</p>
          </div>
          <ProgramStartEnd startDate={program.startDate} endDate={program.endDate} />

          <div className="flex gap-4">
            <p>Amount per carton: </p>
            <p>{program.perCartonCost || '-'} $</p>
          </div>
          <div className="my-5 space-y-2">
            <p>{lang === 'en' ? program.englishDescription : program.frenchDescription}</p>
          </div>
          {!detail && (
            <div className="flex gap-5 max-sm:flex-col">
              <div className="my-5 flex flex-col items-baseline gap-1 space-y-2">
                <p className="font-bold">User info</p>
                <div className="flex gap-4">
                  <p>User rewarded amount: </p>
                  <p>{userRewardedAmount || 0} $</p>
                </div>
                <div className="flex gap-4">
                  <p>User units sold: </p>
                  <p>{userSoldUnits || 0}</p>
                </div>
              </div>
              <div className="my-5 flex flex-col items-baseline gap-1 space-y-2">
                <p className="font-bold">Store info</p>
                <div className="flex gap-4">
                  <p>Store rewarded amount: </p>
                  <p>{program.totalPayments || 0} $</p>
                </div>
                <div className="flex gap-4">
                  <p>Store units sold: </p>
                  <p>{program.ctnsBooked || 0}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {detail && <>{children}</>}

      <div className="flex flex-col gap-2 sm:flex-row">
        {detail ? (
          <Button full secondary onClick={() => navigate('..')}>
            {translate('common.return')}
          </Button>
        ) : (
          <Button full secondary onClick={() => navigate(`${program.id}`)}>
            Details
          </Button>
        )}
        <Button full primary icon="document-scanner" onClick={() => navigate(detail ? `scan` : `${program.id}/scan`)}>
          {translate('components.Extrahub.ExtraHubCard.payNowButton')}
        </Button>
      </div>
    </div>
  )
}

RetailerExtraHubCard.propTypes = {
  children: node,
  program: object.isRequired,
  contactId: string,
  detail: bool
}

export default RetailerExtraHubCard
