import React, { useContext } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { array, string } from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'

import ActionHeaderContext from 'context/ActionHeaderContext'

const Header = tw.h1`font-semibold text-2xl`
const Breadcrumb = styled(({ isActive, ...props }) => <Link {...props} />)`
  ${tw`flex space-x-2`}
  ${({ isActive }) =>
    isActive ? tw`text-slate-900 pointer-events-none cursor-auto` : tw`text-slate-500 hover:text-slate-800`}
`

export const BreadcrumbHeader = ({ headerContent, root }) => {
  const location = useLocation()
  const context = useContext(ActionHeaderContext)

  // find the Header Content that matches the current action route
  const path = location.pathname.substring()
  const match = Object.keys(headerContent).find((key) => matchPath(`${root}/${key}`, path))
  if (!match) return null

  // extract Header Content informations
  const content = headerContent[match]

  const actions = context?.actions
  const { params } = matchPath(`${root}/${match}`, path)

  const header = actions ? content.header?.(params, actions) : content.header?.(params)
  const breadcrumbs = actions ? content.breadcrumbs?.(params, actions) : content.breadcrumbs?.(params)
  const headerActions = actions ? content.headerActions?.(params, actions) : content.headerActions?.(params)

  return (
    <div className="col-span-12 space-y-4 max-md:pt-20">
      <div className="space-y-2">
        <div className="flex justify-between">
          <div className="flex space-x-2">
            {breadcrumbs.map((breadcrumb, i) => {
              const isLast = i + 1 === breadcrumbs.length

              return (
                <Breadcrumb to={breadcrumb.href} key={i} isActive={isLast}>
                  <span>{breadcrumb.text}</span>
                  {!isLast && <span>/</span>}
                </Breadcrumb>
              )
            })}
          </div>
        </div>
        <div className="flex items-end justify-between">
          <Header>{header}</Header>
          {Boolean(headerActions?.length) && <div className="mb-1 flex space-x-4">{headerActions}</div>}
        </div>
      </div>
      <hr className="w-full" />
    </div>
  )
}

BreadcrumbHeader.propTypes = {
  headerContent: array,
  root: string.isRequired
}

export default BreadcrumbHeader
