import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import pick from 'lodash/pick'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import moment from 'moment'
import { denormalize } from 'normalizr'
import { createSelector } from 'reselect'

import { lastSyncs } from 'store/auth/selectors'
import { customer as customerSchema } from 'store/schema'
import { allOpenSurveys } from 'store/surveys/selectors'
import { currentTerritory } from 'store/territories/selectors'

import {
  DEFAULT_AWR_BRAND,
  DEFAULT_STORE_SORT,
  fieldOrder,
  filterAllowedRequiredValues,
  SKU_MAX_QTY_BY_BANNER
} from 'utils/constants'
import { calculatedDistance, getProgramStatusAndFund } from 'utils/helpers'
import composeInvestmentData from 'utils/investments'
import { formatPerformanceData } from 'utils/performance'

const fullState = (state) => state
const auth = (state) => omit(state.auth, '_persist')
export const allCustomers = (state) => omit(state.customers, '_persist')
const allTerritories = (state) => omit(state.territories, '_persist')
const allScopes = (state) => omit(state.scopes, '_persist')
const allGoFunds = (state) => omit(state.goFunds, '_persist')
const allProducts = (state) => omit(state.products, '_persist')
const awrSortingOption = (state, props) => props.awrSortingOption
const kpiOption = (state, props) => props.kpiOption
const coords = createSelector(auth, ({ coords }) => coords)

const preferredLanguage = createSelector(auth, ({ user }) => user.preferredLanguage || 'english')
export const customerIdFromProps = (state, props) => props.customerId

const currentTerritoryCustomerIds = createSelector(currentTerritory, (territory) =>
  territory ? territory.customers : []
)

const allTerritoryCustomerIds = createSelector(allTerritories, (territories) => {
  return Object.values(territories).map((t) => pick(t, ['id', 'name', 'customers']))
})

export const currentCustomer = createSelector(customerIdFromProps, allCustomers, (customerId, customers) => {
  return customers[customerId]
})

export const getPerfData = createSelector(currentCustomer, (customer) => {
  const { 228: WTD = 0, 227: AWR13 = 0 } = customer?.performance || {}
  return { AWR13, WTD }
})

export const getBannerOwnership = createSelector(currentCustomer, (customer) => {
  if (!customer) return []
  const { ownershipType, bannerDetails } = customer
  const banners = bannerDetails?.bannerOwnerships
  if (banners) {
    return (
      banners.find((item) => item.ownershipType === ownershipType) ||
      banners.find((item) => item.ownershipType === 'DODO & Independent')
    )
  }
  return {}
})

export const customerConsumerOffers = createSelector(currentCustomer, (customer) => customer?.consumerOffers || [])

export const allCustomerSla = createSelector(currentCustomer, (customer) => customer?.sla || [])

export const customerSlaLatestUpdate = createSelector(allCustomerSla, (customerSla) => {
  if (!customerSla?.length) return null
  return orderBy(customerSla, ['updatedAt'], ['desc'])[0].updatedAt
})

export const customerKeyCards = createSelector(currentCustomer, (customer) => customer?.keyCards || [])

export const customerProjectLinks = createSelector(currentCustomer, (customer) => customer?.projectLinks || [])

export const orderComplianceThresholds = createSelector(
  getPerfData,
  getBannerOwnership,
  ({ AWR13, WTD }, bannerOwnership) => {
    const emergencyThreshold =
      bannerOwnership?.emergencyOrderApprovalThreshold || bannerOwnership?.emergencyOrderApprovalThreshold === 0
        ? bannerOwnership?.emergencyOrderApprovalThreshold / 100
        : 1.5
    const saqThreshold =
      bannerOwnership?.saqApprovalThreshold || bannerOwnership?.saqApprovalThreshold === 0
        ? bannerOwnership?.saqApprovalThreshold / 100
        : 0.5

    return {
      emergency: Math.floor(Math.max(AWR13 * emergencyThreshold - WTD, 0)),
      saq: Math.max(Math.floor(AWR13 * saqThreshold), 0)
    }
  }
)

export const customerTerritory = createSelector(
  currentCustomer,
  allTerritoryCustomerIds,
  (currentCustomer, territories) => {
    const currentCustomerTerritory = orderBy(
      territories.filter(({ customers }) => customers?.includes(currentCustomer.id)),
      'name',
      'desc'
    )[0]
    return currentCustomerTerritory?.id
  }
)

export const currentCustomerTerritory = createSelector(
  currentCustomer,
  auth,
  allTerritoryCustomerIds,
  (currentCustomer, { currentTerritoryId }, territories) => {
    if (!currentCustomer) return currentTerritoryId
    const currentTerritoryIncludesCurrentCustomer = territories[currentTerritoryId]?.customers?.includes(
      currentCustomer.id
    )
    if (currentTerritoryIncludesCurrentCustomer) return currentTerritoryId
    if (currentCustomer.territories?.length) return orderBy(currentCustomer.territories, 'name', 'desc')[0].id
    const currentCustomerTerritory = orderBy(
      territories.filter(({ customers }) => customers?.includes(currentCustomer.id)),
      'name',
      'desc'
    )[0]
    return currentCustomerTerritory?.id
  }
)

const customersInTerritory = createSelector(currentTerritoryCustomerIds, allCustomers, (customerIds, customers) => {
  if (!customerIds || !customers || isEmpty(customers)) return null
  return customerIds.map((id) =>
    pick(customers[id], [
      'latitude',
      'longitude',
      'awr',
      'primaryContact',
      'address',
      'storePhoneNumber',
      'id',
      'orderDates',
      'name',
      'customerCardPerformance',
      'messages',
      'messagesStatus'
    ])
  )
})

export const awrBrandOptions = createSelector(customersInTerritory, (customers) => {
  if (!customers) return null
  const uniqueBrands = customers.reduce((acc, { awr }) => (awr ? uniq([...acc, ...Object.keys(awr)]) : acc), [])
  if (!uniqueBrands.length) return [{ label: 'No AWR information available', value: DEFAULT_AWR_BRAND }]

  return sortBy(uniqueBrands, (brand) => brand.toLowerCase()).reduce(
    (acc, brand) => (brand === DEFAULT_AWR_BRAND ? acc : [...acc, { label: brand, value: brand }]),
    [
      {
        label: DEFAULT_AWR_BRAND,
        value: DEFAULT_AWR_BRAND
      }
    ]
  )
})

const proximitySortedCustomers = createSelector(customersInTerritory, coords, (customerList, coords) => {
  if (!coords?.coords) return customerList
  return sortBy(
    customerList,
    ({ latitude, longitude }) =>
      latitude && longitude ? calculatedDistance([latitude, longitude], coords.coords) : 40075 // circumference of the earth
  )
})

const alphabeticallySortedCustomers = createSelector(customersInTerritory, (customerList) => {
  return sortBy(customerList, (customer) => {
    const { id, name } = customer
    return name?.toUpperCase() || id
  })
})

const storeIdSortedCustomers = createSelector(customersInTerritory, (customerList) => {
  return sortBy(customerList, ({ id }) => id)
})

const orderDaySortedCustomers = createSelector(customersInTerritory, (customerList) => {
  return sortBy(customerList, ({ orderDates }) => {
    return orderDates ? new Date(orderDates[0]).valueOf() : Date.now() + Date.now()
  })
})

const performanceSortedCustomers = createSelector(
  customersInTerritory,
  awrSortingOption,
  kpiOption,
  (customerList, awrSortingOption, kpiOption) => {
    return sortBy(customerList, ({ awr }) => {
      return awr && awr[awrSortingOption] ? awr[awrSortingOption][kpiOption] : null
    })
  }
)

export const sortedCustomers = (state, props) => {
  const sorters = {
    proximity: proximitySortedCustomers,
    alphabetically: alphabeticallySortedCustomers,
    storeId: storeIdSortedCustomers,
    orderDay: orderDaySortedCustomers,
    performance: performanceSortedCustomers
  }

  const sorter = sorters[props.customerSortingOption] || sorters[DEFAULT_STORE_SORT]
  return sorter(state, props)
}

// CUSTOMER VIEW
export const lastCustomerGoSalesBooked = createSelector(
  currentCustomer,
  (customer) => customer?.lastGoSalesBooked?.dateBooked
)

export const getCreditCardStatus = createSelector(currentCustomer, (customer) => Boolean(customer?.hasCreditCard))

export const isPo98Customer = createSelector(currentCustomer, (customer) => {
  return customer?.bannerDetails?.isPo98
})

export const isPo98EnabledCustomer = createSelector(currentCustomer, (customer) => {
  return customer?.bannerDetails?.isPo98 && customer?.bannerDetails?.po98Enabled
})

export const customerFromUrl = createSelector(currentCustomer, fullState, (customer, state) => {
  if (!customer) return null
  return denormalize(customer, customerSchema, state)
})

export const customerPerformance = createSelector(customerFromUrl, lastSyncs, (customer, lastSyncs) =>
  formatPerformanceData(customer.performance, customer.topSkus, customer.vctmSellOut?.deviceType, {
    ...lastSyncs.customer,
    topSkus: customer.topSkusUpdatedAt,
    vctmSellOutUpdatedAt: customer.vctmSellOutUpdatedAt
  })
)

export const customerSurveys = createSelector(customerFromUrl, (customer) => {
  if (!customer?.surveys) return []
  return customer.surveys
    .filter(
      ({ survey, clarificationNeededCount }) =>
        survey && (moment().isBetween(survey.startDate, survey.endDate, 'day', '[]') || clarificationNeededCount)
    )
    .sort((a, b) => new Date(a.survey.endDate).valueOf() - new Date(b.survey.endDate).valueOf())
})

export const incompleteCustomerSurveys = createSelector(customerFromUrl, (customer) => {
  if (!customer?.surveys) return []
  return customer.surveys
    .filter(
      ({ survey, clarificationNeededCount }) =>
        survey &&
        (moment().isBetween(survey.startDate, survey.endDate, 'day', '[]') || clarificationNeededCount) &&
        survey.completion !== 'complete'
    )
    .sort((a, b) => new Date(a.survey.endDate) - new Date(b.survey.endDate))
})

const customerSurveyIds = createSelector(customerSurveys, (customerSurveys) => {
  return customerSurveys.map(({ surveyId }) => surveyId)
})

export const customerOpenSurveys = createSelector(allOpenSurveys, customerSurveyIds, (openSurveys, surveyIds) => {
  return openSurveys.filter(({ id }) => !surveyIds.includes(id))
})

export const surveyIdFromUrl = (state, props) => props.surveyId

export const customerSurvey = createSelector(
  customerOpenSurveys,
  customerSurveys,
  surveyIdFromUrl,
  (openSurveys, surveys, surveyId) => {
    const { answers = [], survey, ...customerSurvey } = surveys.find((cs) => cs.surveyId === +surveyId) || {}
    const { questions, ...surveyDetails } = survey || openSurveys.find(({ id }) => id === +surveyId) || {}
    if (!questions?.length) return null
    return {
      survey: surveyDetails,
      questions: orderBy(questions, 'sequence'),
      answers,
      customerSurvey
    }
  }
)

const surveyAutofillCustomerIdsFromLocationState = (state, props) => props.location?.state?.targets
export const autofillSurveyTargets = createSelector(
  allCustomers,
  surveyIdFromUrl,
  surveyAutofillCustomerIdsFromLocationState,
  (customers, surveyId, customerIds) => {
    if (!customerIds?.length) return []
    const relevantCustomers = pick(customers, customerIds)
    return Object.values(relevantCustomers).reduce((acc, { surveys }) => {
      if (!surveys?.length) return acc
      const customerSurvey = surveys.find((cs) => cs.surveyId === +surveyId)
      return customerSurvey ? [...acc, customerSurvey] : acc
    }, [])
  }
)

export const customerSurveyInitialValues = createSelector(customerSurvey, (survey) => {
  if (!survey) return null
  const { answers, questions, customerSurvey } = survey
  const questionGroupedAnswers = groupBy(answers || [], 'questionId')
  const { isAutofilled } = customerSurvey
  return questions.reduce((acc, question) => {
    const questionAnswers = questionGroupedAnswers[question.id] || []
    if (!questionAnswers?.length && !isAutofilled) return acc

    if (question.type === 'sox') {
      return {
        ...acc,
        [question.id]: questionAnswers.reduce(
          (soxAcc, { id, answer }) => ({
            ...soxAcc,
            [`ans_${id}`]: answer
          }),
          {}
        )
      }
    }

    const { options, answer } = questionAnswers[0] || {}
    const optIds =
      (options?.length && options.map(({ id }) => id)) ||
      (isAutofilled && question.options?.filter(({ isDefault }) => isDefault).map(({ id }) => id))
    if (['text', 'number'].includes(question.type)) {
      return {
        ...acc,
        [question.id]: answer?.text || (isAutofilled && question.defaultAnswer) || ''
      }
    }
    if (question.type === 'dropdown' && optIds?.length) {
      return {
        ...acc,
        [question.id]: optIds[0]
      }
    }
    if (question.type === 'multi' && optIds?.length) {
      return {
        ...acc,
        [question.id]: optIds
      }
    }
    if (question.type === 'images' && answer?.imgs?.length) {
      return {
        ...acc,
        [question.id]: answer?.imgs
      }
    }
    return acc
  }, {})
})

export const isRestrictedBannerInRestrictedPeriod = createSelector(currentCustomer, (currentCustomer) => {
  return (
    moment().isBetween(SKU_MAX_QTY_BY_BANNER.startDate, SKU_MAX_QTY_BY_BANNER.endDate, 'day', '[]') &&
    currentCustomer?.banner &&
    SKU_MAX_QTY_BY_BANNER.banners.includes(currentCustomer.banner.name)
  )
})

// https://bat-canada.atlassian.net/browse/ADV-458 - block skus in assortment
// const nationallyBlockedSkus = [
//   '10160873',
//   '10163006',
//   '10163762',
//   '10163773',
//   '10169652',
//   '10169655',
//   '10175420',
//   '10175451',
//   '10175452'
// ]

// const blockMessageBySku = {
//   10160873: 'This SKU has been delisted',
//   10160888: 'This SKU has been delisted',
//   10163006: 'This SKU has been delisted',
//   10163728: 'This SKU has been delisted. The suggested alternative is Vuse GO 5000 Creamy Tobacco 20mg 1x1.',
//   10163762: 'This SKU has been delisted. The suggested alternative is Vuse GO 5000 Berry Blend 20mg 1x1',
//   10163772: 'This SKU has been delisted. The suggested alternative is Vuse GO 5000 Mint Ice 20mg 1x1',
//   10163773: 'This SKU has been delisted. The suggested alternative is Vuse GO 5000 Watermelon Ice 20mg 1x1',
//   10169652: 'This SKU has been delisted. The suggested alternative is Vuse GO 5000 Strawberry Kiwi 20mg 1x1',
//   10169655: 'This SKU has been delisted',
//   10175420: 'This SKU has been delisted',
//   10175439: 'This SKU has been delisted',
//   10175451: 'This SKU has been delisted',
//   10175452: 'This SKU has been delisted'
// }

export const customerAssortment = createSelector(
  currentCustomer,
  allProducts,
  auth,
  (customer, products, { user: { preferredLanguage = 'english' } }) => {
    if (!customer) return []
    return Object.values(pick(products, customer.assortment)).map((product) => {
      return {
        ...product,
        name: product[`${preferredLanguage}Name`],
        brand: product[`${preferredLanguage}Brand`],
        variant: product[`${preferredLanguage}Variant`]
      }
    })
  }
)

const customerGoFundProgramsWithBudgetEndDate = createSelector(
  customerFromUrl,
  allGoFunds,
  preferredLanguage,
  (customer, goFunds, preferredLanguage) => {
    if (!customer || isEmpty(goFunds)) return null
    return customer?.goFundPrograms
      ?.filter(Boolean)
      .map((gfp) => getProgramStatusAndFund(goFunds[gfp?.goFundId], gfp, preferredLanguage))
  }
)

export const goFundIdFromProps = (state, props) => props.programId

export const customerPrograms = createSelector(customerGoFundProgramsWithBudgetEndDate, (goFundPrograms) => {
  if (!goFundPrograms)
    return {
      current: [],
      ended: [],
      completed: []
    }

  const { current = [], ended = [], completed = [] } = groupBy(goFundPrograms, 'status')
  return {
    current: sortBy(current, ({ endDate }) => new Date(endDate).valueOf()),
    ended: sortBy(ended, ({ endDate }) => new Date(endDate).valueOf()),
    completed: orderBy(
      completed,
      [
        ({ updatedAt, officialPaymentDate, endDate }) => {
          return moment(officialPaymentDate?.split('T')[0] || updatedAt || endDate).valueOf()
        }
      ],
      ['desc']
    )
  }
})

export const customerScopeConfirmations = createSelector(customerFromUrl, allScopes, (customer, scopes) => {
  if (!customer?.scopeRequests) return []
  return customer.scopeRequests.map((request) => ({ ...scopes[request.scopeId], scopeRequests: [request] }))
})

export const customerIntelClarifications = createSelector(customerFromUrl, (customer) => {
  if (!customer?.intel) return []
  const actionableIntel = customer.intel.filter((i) => {
    const allowedRequiredValues = filterAllowedRequiredValues(i.type)
    const hasUnansweredQuestions = i.feedback && i.feedback.some(({ answer }) => !answer)
    const hasDetailRequests =
      i.detailRequests && i.detailRequests.some((field) => i[field] === null && allowedRequiredValues.includes(field))
    return hasUnansweredQuestions || hasDetailRequests
  })
  return actionableIntel.map((intel) => ({
    isIntelFeedback: true,
    detailRequests: orderBy(intel.detailRequests, (field) => fieldOrder.lastIndexOf(field)),
    ...intel
  }))
})

export const getCustomerIntelRequestCount = createSelector(
  customerScopeConfirmations,
  customerIntelClarifications,
  (scopes, feedback) => scopes.length + feedback.length
)

export const getCustomerGoFundsEndingCount = createSelector(customerPrograms, ({ ended }) => ended?.length)
export const customerIntel = createSelector(customerFromUrl, ({ intel }) => {
  if (!intel) return { current: [], past: [] }
  const { current, past } = intel.reduce(
    ({ current, past }, intelActivity) => {
      const dateToCheck =
        intelActivity.endDate || moment(intelActivity.startDate || intelActivity.createdAt).add(90, 'days')
      const isPast = moment().isAfter(dateToCheck, 'day')

      return {
        current: isPast ? current : [...current, intelActivity],
        past: isPast ? [...past, intelActivity] : past
      }
    },
    {
      current: [],
      past: []
    }
  )
  return {
    current: groupBy(
      current,
      ({ productCategory, type, manufacturer, brand }) =>
        `${productCategory}|${manufacturer}|${brand}|${type.split('(')[0]}`
    ),
    past: groupBy(
      past,
      ({ productCategory, type, manufacturer, brand }) =>
        `${productCategory}|${manufacturer}|${brand}|${type.split('(')[0]}`
    )
  }
})

export const customerVapeData = createSelector(customerFromUrl, ({ ngp }) => ngp)
export const customerThpData = createSelector(customerFromUrl, ({ thp }) => thp)

const investmentView = (state, props) => {
  return props.pathname.split('/').pop()
}
export const customerInvestments = createSelector(customerFromUrl, auth, investmentView, composeInvestmentData)

export const groupedScopeConfirmations = createSelector(allCustomers, allScopes, (customers, scopes) => {
  const scopeRequests = Object.values(customers)
    .filter(({ scopeRequests }) => scopeRequests && scopeRequests.length)
    .reduce((acc, { scopeRequests, id, name, address }) => {
      return scopeRequests
        .map((scope) => ({
          ...scope,
          customerId: id,
          customerName: name,
          customerAddress: address?.line1
        }))
        .concat(acc)
    }, [])

  const scopeGroupedRequests = groupBy(scopeRequests, 'scopeId')
  return Object.entries(scopeGroupedRequests).map(([scopeId, scopeRequests]) => ({ ...scopes[scopeId], scopeRequests }))
})

export const customerComments = createSelector(customerFromUrl, ({ comments = [] }) =>
  sortBy(comments, ({ createdAt }) => -new Date(createdAt))
)

export const erpOptions = createSelector(allCustomers, (customers) =>
  Object.values(customers).map(({ id, name, banner, address, outletSubtype }) => ({
    id,
    name,
    banner,
    outletSubtype,
    address
  }))
)

export const customerSellInPrograms = createSelector(currentCustomer, (customer) => customer?.sellInPrograms || [])

export const incompleteCustomerSellInPrograms = createSelector(customerSellInPrograms, (sellinPrograms) => {
  const programs = sellinPrograms ? Object.values(sellinPrograms) : []
  return programs.filter((program) => program.status !== 'accepted')
})

export const customerOrders = createSelector(customerFromUrl, (customer) => customer?.orders || [])

export const newCustomerAssortment = createSelector(
  customerFromUrl,
  (customer) =>
    customer?.assortment.filter((assorment) => assorment.category === 'fmc' || assorment.category === 'vype') || []
)

export const recommendedQtyByTypeForCustomer = createSelector(newCustomerAssortment, (assortments) => {
  return assortments.reduce(
    (totals, { category, materialGroup, recommendedQty, basePriceInDollars }) => {
      const updateTotals = (key) => {
        totals[key].totalQty += recommendedQty
        totals[key].totalPrice += recommendedQty * basePriceInDollars
      }

      if (category === 'fmc' || materialGroup === 'FG Non Tobacco Prod') {
        updateTotals('fmc')
      } else if (category === 'vype') {
        updateTotals('vape')
      }

      updateTotals('grandTotal')

      return totals
    },
    {
      fmc: { totalQty: 0, totalPrice: 0 },
      vape: { totalQty: 0, totalPrice: 0 },
      grandTotal: { totalQty: 0, totalPrice: 0 }
    }
  )
})

export const pendingCustomerReminders = createSelector(customerFromUrl, (customer) => {
  const reminders = customer?.reminders || []

  return reminders.map((reminder) => ({ id: reminder.id, content: reminder.content, checked: !!reminder.completedAt }))
})

export const customerProductDrivers = createSelector(customerFromUrl, (customer) => {
  const productBrandDrivers = customer?.productBrandDrivers || []
  const productTypeDrivers = customer?.productTypeDrivers || []

  const fmcProductTypeDrivers = productTypeDrivers?.filter((driver) => driver.category === 'FMC')
  const vapeProductTypeDrivers = productTypeDrivers?.filter((driver) => driver.category === 'Vape')

  return { productBrandDrivers, fmcProductTypeDrivers, vapeProductTypeDrivers }
})

export const currentYearInvoicedAmountForCustomer = createSelector(customerFromUrl, (customer) => {
  return customer?.cyInvoicedTotal
})
